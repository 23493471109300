import React, { useState, useEffect } from "react";
import Network from "./Requests";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import {
  setInfoProduct,
  setType,
  setListProduct,
  setCurrentProduct,
  setSave,
} from "../store/reducers/productSlice";
import { setOpenStore } from "../store/reducers/productStoreSlice";
import { setStore } from "../store/reducers/storeSlice";
import useInput from "../hooks/useInput";
import ModalStore from "./ModalStore";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export default function ProductInfo(props) {
  const dispatch = useDispatch();
  const [view, setView] = useState();
  const productList = useSelector((state) => state.product.list);
  const storeCurrent = useSelector((state) => state.store.current);
  const productCurrent = useSelector((state) => state.product.current);
  const productCurrentInfo = useSelector((state) => state.product.info);
  const save = useSelector((state) => state.product.save);
  const calculation = useInput(productCurrentInfo?.guidCalculation);
  const skyboxPath = useInput(productCurrentInfo?.skyboxPath);
  const iOsSkyboxPath = useInput(productCurrentInfo?.iOsSkyboxPath);
  const externalUrl = useInput(productCurrentInfo?.externalUrl);
  const type = useSelector((state) => state.product.type);

  useEffect(() => {
    const fetch = async () => {
      if (productCurrent) {
        const product = await new Network().Get(productCurrent);
        dispatch(setInfoProduct(product));
      }
    };
    fetch();
  }, [productCurrent]);

  useEffect(() => {
    const fetch = async () => {
      if (productCurrentInfo) {
        const icon = await new Network().CheckMainIconContainedInCalculation(
          productCurrentInfo?.guidCalculation
        );
        if (!icon) {
          setView(true);
        } else {
          setView(false);
        }
      }
    };
    fetch();
  }, [productCurrentInfo]);

  const openStore = (e) => {
    dispatch(setType(e));
    dispatch(setOpenStore(true));
  };

  const [checked, setChecked] = React.useState(false);

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };

  const [open, setOpen] = React.useState();
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const [openSuccess, setOpenSuccess] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const [errortext, setText] = React.useState("");
  const { vertical, horizontal } = state;

  const saveOrUpdate = () => {
    const fetch = async () => {
      if (save) {
        let insert = {};
        insert.guidCalculation = calculation?.value;
        insert.skyboxPath = skyboxPath?.value;
        insert.externalUrl = externalUrl?.value;
        insert.idStore = storeCurrent?.id;

        if (!checked) {
          insert.iOsSkyboxPath = iOsSkyboxPath?.value;
        }
        Object.preventExtensions(insert);
        const send = await new Network().addProduct(insert);
        console.log(send);
        if (send?.status == "error") {
          setText(send.text);
          dispatch(setCurrentProduct(productList[0]));
          setOpen(true);
        } else {
          dispatch(setListProduct([send, ...productList]));
          dispatch(setSave(false));
          dispatch(setCurrentProduct(send?.token));
          setOpenSuccess(true);
        }
      } else {
        let insert = JSON.parse(JSON.stringify(productCurrentInfo));
        insert.doNotApplyIosSkybox = true;
        insert.guidCalculation = calculation?.value;
        insert.skyboxPath = skyboxPath?.value;
        insert.externalUrl = externalUrl?.value;

        if (!checked) {
          insert.iOsSkyboxPath = iOsSkyboxPath?.value;
        }

        Object.preventExtensions(insert);
        const send = await new Network().UpdateProduct(insert);
        if (send?.status == "error") {
          setText(send.text);
          setOpen(true);
        } else {
          dispatch(setSave(false));
          setOpenSuccess(true);
        }
      }
    };
    fetch();
  };

  const setInput = (e) => {
    if (type == "web") {
      skyboxPath.onChange(e);
    } else if (type == "ios") {
      iOsSkyboxPath.onChange(e);
    }
  };

  return (
    <div className="wrap">
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={7000}
        onClose={handleClose}
      >
        <div className="messageAl">
          <div className="messageAl--text">
            <span>
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="ErrorOutlineIcon"
              >
                <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
              </svg>{" "}
            </span>
            <span>{errortext}</span>
          </div>
          <CloseIcon fontSize="small" onClick={handleClose} />
        </div>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={7000}
        onClose={handleCloseSuccess}
      >
        <div className="messageAlSuccses">
          <div className="messageAl--text">
            <span>
              <svg
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="SuccessOutlinedIcon"
              >
                <path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"></path>
              </svg>{" "}
            </span>
            <span>Данные сохранены!!!</span>
          </div>

          <CloseIcon fontSize="small" onClick={handleCloseSuccess} />
        </div>
      </Snackbar>
      <ModalStore setInput={(e) => setInput(e)} />
      <div className="product-info">
        <div className="info-row">
          <div className="info-name">Расчёт(guidCalculation)</div>
          <div className="info-name">
            {productCurrentInfo?.name && productCurrentInfo?.name}
          </div>
          <div className="info-inputs">
            <input type="text" placeholder="guidCalculation" {...calculation} />
          </div>
        </div>
        <div className="info-row">
          <div className="info-name">Скайбокс-веб (skyboxPath)</div>
          <div className="info-inputs">
            <input type="text" placeholder="skyboxPath" {...skyboxPath} />
          </div>
          <div className="info-button">
            <Button variant="contained">Загрузить с диска</Button>
            <Button variant="contained" onClick={() => openStore("web")}>
              Выбрать из готовых
            </Button>
          </div>
        </div>
        <div className="info-row">
          <div className="info-name">Скайбокс-iOS (iOsSkyboxPath)</div>
          <div className="info-inputs">
            <input type="text" placeholder="iOsSkyboxPath" {...iOsSkyboxPath} />
          </div>
          <div className="info-button">
            <Button variant="contained">Загрузить с диска</Button>
            <Button variant="contained" onClick={() => openStore("ios")}>
              Выбрать из готовых
            </Button>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChecked} />}
              label="Не применять"
            />
          </div>
        </div>
        <div className="info-row">
          <div className="info-name">URL карточки товара(externalUrl)</div>
          <div className="info-inputs">
            <input type="text" placeholder="externalUrl" {...externalUrl} />
          </div>
        </div>
        {view && (
          <div className="warning">
            <div className="warning-item">
              <span>Warning</span>Иконка отсутствует
            </div>
          </div>
        )}
        <div className="info-button">
          <Button
            className="info-save"
            variant="contained"
            onClick={saveOrUpdate}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );
}
